import * as Sentry from "@sentry/sveltekit";
import "$lib/supabase/supabase";

// If you don't want to use Session Replay, remove the `Replay` integration, 
// `replaysSessionSampleRate` and `replaysOnErrorSampleRate` options.
Sentry.init({
    dsn: "https://642ca632341e8157e8187d121bb8deee@o4505846445637632.ingest.sentry.io/4505846861529088",
    tracesSampleRate: 1,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1,
    integrations: [new Sentry.Replay()]
})

export const handleError = Sentry.handleErrorWithSentry();